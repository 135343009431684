<template>
  <v-card class="mb-8">
    <v-card-text>
      <v-btn
        color="primary"
        @click="choiceProducts"
        :disabled="disabledQuery"
      >
        <span>{{'product.action.choice_product'| t}}</span>
      </v-btn>

      <div class="mt-4 py-4">
        <item
          @removeItem="removeItem"
          v-for="(item, index) in requestData.items"
          :key="index"
          class="mb-6"
          :index="index"
          :queryResult="queryResult"
          v-model="requestData.items[index]"
        ></item>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    disabledQuery: Boolean,
    queryResult: Object,
    value: {
      type: Object,
    },
  },
  data: () => ({
    requestData: null,
  }),
  methods: {
    removeItem(index) {
      this.$delete(this.requestData.items, index)
    },
    choiceProducts() {
      this.$productSelector({
        applyCallback: this.onProductSelected,
      })
    },
    async onProductSelected(product) {
      if(!product.multi_instances) {
        this.addProductInstance(product, product.instances[0])
        return
      }

      await this.$helper.delay(1)
      this.$apopup.base({
        title: this.$t('order.action.choice.product_instance'),
        bodySlot: () => import('./choiceProductInstance.vue'),
        product,
        disabledApply: data => !data,
        applyCallback: data => {
          this.addProductInstance(product, data)
        },
      })
    },
    addProductInstance(product, instance) {
      if(!instance) return console.warn('empty instance', instance)
      if(!instance.id) return console.warn('empty instance id', instance.id)
      const index = this.requestData.items.findIndex(item => item.product_instance_id === instance.id)
      if(index == -1) {
        this.requestData.items.push({
          product_instance_id: instance.id,
          quantity: 1,
        })
      }

      else {
        this.$snotify.warning(null, this.$t('order.notify.add_product_to_order.product_instance.exist'))
      }
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.requestData = window.eagleLodash.cloneDeep(this.value)
      },
    },
    requestData: {
      deep: true,
      handler() {
        if(window.eagleLodash.isEqual(this.requestData, this.value)) return
        this.$emit('input', window.eagleLodash.cloneDeep(this.requestData))
      },
    },
  },
  components: {
    item: () => import('./item.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
